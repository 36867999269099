import { Home } from '@/model/Home';
import { HomeSecond } from '@/model/HomeSecond';
import api from '@/services/api';

export const getHome = async (): Promise<Home> => {
  const { data } = await api.get<Home>('/home/v1');
  return data;
};

export const getHomeSecond = async (): Promise<HomeSecond> => {
  const { data } = await api.get<HomeSecond>('/home/v1/second');
  return data;
};
