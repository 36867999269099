import Breadcrumbs from '@/components/Breadcrumbs';
import { CardVideo } from '@/components/CardVideo';
import { ActionProps, Dialog } from '@/components/Dialog';
import Loader, { LoaderStatus } from '@/components/Loader';
import { Event } from '@/model/Event';
import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { FormErrors } from '@/hooks/useForm';
import FilterVector from '@/assets/images/FilterVector';
import H5 from '@/components/H5';
import { Coupon } from '@/model/Coupon';
import { EventInformation } from '@/model/EventInformation';
import { Operator } from '@/model/Operator';
import { SimpleStore } from '@/model/SimpleStore';
import { Page } from '@/model/Page';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ShouldShowModal } from '../../types';
import { FilterContent } from '../../components/FilterContent';
import { CouponContent } from '../../components/CouponContent';

interface StateProps {
  status: LoaderStatus;
  page: Page<Event, Event>;
  events: Event[];
  title: string;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  stores: SimpleStore[];
  operators: Operator[];
  formDataFilter: Event;
  formErrorsFilter: FormErrors<Event>;
  event?: Event;
  coupon?: Coupon;
  formDataEventInformation: EventInformation;
  formErrorsEventInformation: FormErrors<EventInformation>;
}

interface DispatchProps {
  onToggle: () => void;
  onShouldShowModal: ({
    value,
    newTitleModal,
    eventSelected,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    eventSelected?: Event;
  }) => void;
  onChangeInputFilter: <N extends keyof Event, V>(name: N, value: V) => void;
  onGetOperators: (storeId: string) => void;
  onFilter: () => void;
  clearFilter: () => void;
  onChangeInputEventInformation: <N extends keyof EventInformation, V>(name: N, value: V) => void;
  onSaveEventInformation: () => void;
  onGetMoreRecords: () => void;
}

type Props = StateProps & DispatchProps;

export const DiscountUI: React.FC<Props> = ({
  status,
  page,
  events,
  title,
  visible,
  onToggle,
  onShouldShowModal,
  shouldShowModal,
  stores,
  operators,
  formDataFilter,
  formErrorsFilter,
  onChangeInputFilter,
  onGetOperators,
  onFilter,
  clearFilter,
  event,
  coupon,
  formDataEventInformation,
  formErrorsEventInformation,
  onChangeInputEventInformation,
  onSaveEventInformation,
  onGetMoreRecords,
}): JSX.Element => {
  const renderActionDialogToCancelFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => clearFilter(),
    theme: 'secondary',
  };
  const renderActionDialogToFilter: ActionProps = {
    title: 'Aplicar',
    onClick: (): void => onFilter(),
    theme: 'primary',
  };
  const hasMore = (): boolean => {
    let b = true;
    if (events && page.total) {
      if (events.length === page.total) {
        b = false;
      }
    }
    return b;
  };
  return (
    <Fragment>
      <Loader status={status} />
      <Breadcrumbs
        mainTitle="Descontos"
        parent="Soluções"
        subParent="Safe Checkout"
        title="Descontos"
      />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={shouldShowModal === ShouldShowModal.filter ? 'right' : 'center'}
        isContentWithCard={shouldShowModal !== ShouldShowModal.filter}
        actions={[
          {
            [ShouldShowModal.filter]: renderActionDialogToCancelFilter,
            [ShouldShowModal.coupon]: {},
          }[shouldShowModal],
          {
            [ShouldShowModal.filter]: renderActionDialogToFilter,
            [ShouldShowModal.coupon]: {},
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.filter]: (
              <FilterContent
                stores={stores}
                operators={operators}
                formData={formDataFilter}
                formErrors={formErrorsFilter}
                onChangeInput={onChangeInputFilter}
                onGetOperators={onGetOperators}
              />
            ),
            [ShouldShowModal.coupon]: (
              <CouponContent
                event={event}
                coupon={coupon}
                formData={formDataEventInformation}
                formErrors={formErrorsEventInformation}
                onChangeInput={onChangeInputEventInformation}
                onSave={onSaveEventInformation}
              />
            ),
          }[shouldShowModal]
        }
      </Dialog>
      <Container fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <div className="pageTitle" style={{ display: 'grid' }}>
            <H5>{`${page.total} Descontos`}</H5>
          </div>
          <div className="button-filter-container">
            <div className="filter-container">
              <div
                className="filter-content"
                onClick={(): void => {
                  onToggle();
                  onShouldShowModal({
                    value: ShouldShowModal.filter,
                    newTitleModal: '',
                  });
                }}
              >
                <FilterVector />
              </div>
            </div>
          </div>
        </div>
        <InfiniteScroll
          dataLength={events.length}
          next={onGetMoreRecords}
          hasMore={hasMore()}
          loader={<h4></h4>}
          endMessage={<p style={{ textAlign: 'center' }}></p>}
        >
          <Row className="widget-grid">
            {events.map(data => (
              <CardVideo key={data.id} event={data} onShouldShowModal={onShouldShowModal} />
            ))}
          </Row>
        </InfiniteScroll>
      </Container>
    </Fragment>
  );
};
