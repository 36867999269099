import H5 from '@/components/H5';
import SvgIcon from '@/components/SvgIcon';
import { Event } from '@/model/Event';
import { toCurrency } from '@/utils/currency';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Label, Media, Row } from 'reactstrap';
import { FormErrors } from '@/hooks/useForm';
import { EventInformation } from '@/model/EventInformation';
import dayjs from 'dayjs';
import { ModalStyle } from '../../types';

interface StateProps {
  event?: Event;
  formData: EventInformation;
  formErrors: FormErrors<EventInformation>;
}

interface DispatchProps {
  onChangeInput: <N extends keyof EventInformation, V>(name: N, value: V) => void;
  onSave: () => void;
}

type Props = StateProps & DispatchProps;

export const CouponContent: React.FC<Props> = ({
  event,
  formData,
  onChangeInput,
  onSave,
}): JSX.Element => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = (): void => {
    setWidth(window.innerWidth);
  };

  const getStyle = (): ModalStyle => {
    let isDark = false;
    const elements = document.getElementsByTagName('body');
    if (elements && elements.length > 0) {
      const element = elements[0];
      const classes = element.classList;
      if (classes && classes.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < classes.length; i++) {
          if (classes[i] === 'dark-only') {
            isDark = true;
            break;
          }
        }
      }
    }
    if (isDark) {
      return {
        type: 0,
        background: 'transparent',
        color: 'rgba(255, 255, 255, 0.6)',
      };
    }
    return {
      type: 1,
      background: '#fff',
      color: '#212529',
    };
  };

  const mountStars = (): string[] => {
    const list: string[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 5; i++) {
      list.push(
        formData.stars && formData.stars > 0 && formData.stars >= i ? 'fill-star' : 'stroke-star',
      );
    }
    return list;
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <React.Fragment>
      {event && (
        <Row className="widget-grid coupon">
          <Col className="col-xxl-12 col-sm-12 box-col-12">
            <Card className="height-equal">
              <CardHeader
                className="card-no-border"
                style={
                  width < 768
                    ? {
                        backgroundColor: getStyle().background,
                        padding: 0,
                      }
                    : { backgroundColor: getStyle().background, paddingTop: 0, paddingBottom: 0 }
                }
              >
                <div className="header-top">
                  <H5 className="m-0" style={{ color: getStyle().color }}>
                    {event.store}
                  </H5>
                </div>
              </CardHeader>
              <CardBody
                style={
                  width < 768
                    ? {
                        padding: 0,
                      }
                    : { paddingTop: 0, paddingBottom: 0 }
                }
              >
                <div className="main-container-video">
                  <iframe
                    src={`https://drive.google.com/file/d/${event.fileId}/preview`}
                    frameBorder="0"
                    allow="autoplay"
                    allowFullScreen={true}
                  ></iframe>
                </div>

                {width < 768 ? (
                  <>
                    <label style={{ color: getStyle().color }}>
                      {`Sangria, Valor : `}
                      <span className="txt-secondary">{toCurrency(event.couponValue)}</span>
                    </label>
                    <div style={{ display: 'inline-flex' }}>
                      {mountStars().map((data, index) => (
                        <SvgIcon
                          key={`icon-${index}`}
                          iconId={data}
                          style={{ width: '20px', height: '20px' }}
                          onClick={() => onChangeInput('stars', index + 1)}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="header-top">
                    <label style={{ color: getStyle().color }}>
                      {`Data ${dayjs(event.eventDate).format('DD/MM/YYYY HH:mm:ss')}, Valor : `}
                      <span className="txt-secondary">{toCurrency(event.couponValue)}</span>
                    </label>
                    <div style={{ display: 'inline-flex' }}>
                      {mountStars().map((data, index) => (
                        <SvgIcon
                          key={`icon-${index}`}
                          iconId={data}
                          style={{ width: '20px', height: '20px' }}
                          onClick={() => onChangeInput('stars', index + 1)}
                        />
                      ))}
                    </div>
                  </div>
                )}

                <hr style={{ height: '1px solid', color: getStyle().color }} />
                <div>
                  {width < 768 ? (
                    <>
                      <Media style={{ width: '158px' }}>
                        <Label
                          className="col-form-label m-r-10"
                          style={{ color: getStyle().color }}
                        >
                          Salvar ocorrência:
                        </Label>
                        <Media body className="text-end icon-state switch-sm">
                          <Label className="switch">
                            <Input
                              type="checkbox"
                              disabled={false}
                              checked={formData.saveOccurrence}
                              onChange={() =>
                                onChangeInput('saveOccurrence', !formData.saveOccurrence)
                              }
                            />
                            <span className="switch-state bg-primary"></span>
                          </Label>
                        </Media>
                      </Media>
                      {formData.saveOccurrence && (
                        <div style={{ display: 'inline-flex' }}>
                          <Media>
                            <Label
                              className="col-form-label m-r-10"
                              style={{ color: getStyle().color }}
                            >
                              Desvio:
                            </Label>
                            <Media body className="text-end icon-state switch-sm">
                              <Label className="switch">
                                <Input
                                  type="checkbox"
                                  disabled={false}
                                  checked={formData.deviation}
                                  onChange={() => {
                                    onChangeInput('deviation', !formData.deviation);
                                    onChangeInput('operationalError', formData.deviation);
                                  }}
                                />
                                <span className="switch-state bg-primary"></span>
                              </Label>
                            </Media>
                          </Media>
                          <Media style={{ marginLeft: '15px' }}>
                            <Label
                              className="col-form-label m-r-10"
                              style={{ color: getStyle().color }}
                            >
                              Erro operacional:
                            </Label>
                            <Media body className="text-end icon-state switch-sm">
                              <Label className="switch">
                                <Input
                                  type="checkbox"
                                  disabled={false}
                                  checked={formData.operationalError}
                                  onChange={() => {
                                    onChangeInput('operationalError', !formData.operationalError);
                                    onChangeInput('deviation', formData.operationalError);
                                  }}
                                />
                                <span className="switch-state bg-primary"></span>
                              </Label>
                            </Media>
                          </Media>
                        </div>
                      )}
                    </>
                  ) : (
                    <Row>
                      <Col className="col-4">
                        <div style={{ display: 'inline-flex' }}>
                          <Media>
                            <Label
                              className="col-form-label m-r-10"
                              style={{ color: getStyle().color }}
                            >
                              Salvar ocorrência:
                            </Label>
                            <Media body className="text-end icon-state switch-sm">
                              <Label className="switch">
                                <Input
                                  type="checkbox"
                                  disabled={false}
                                  checked={formData.saveOccurrence}
                                  onChange={() =>
                                    onChangeInput('saveOccurrence', !formData.saveOccurrence)
                                  }
                                />
                                <span className="switch-state bg-primary"></span>
                              </Label>
                            </Media>
                          </Media>
                        </div>
                      </Col>
                      {formData.saveOccurrence && (
                        <>
                          <Col className="col-4">
                            <div style={{ display: 'inline-flex' }}>
                              <Media>
                                <Label
                                  className="col-form-label m-r-10"
                                  style={{ color: getStyle().color }}
                                >
                                  Desvio:
                                </Label>
                                <Media body className="text-end icon-state switch-sm">
                                  <Label className="switch">
                                    <Input
                                      type="checkbox"
                                      disabled={false}
                                      checked={formData.deviation}
                                      onChange={() => {
                                        onChangeInput('deviation', !formData.deviation);
                                        onChangeInput('operationalError', formData.deviation);
                                      }}
                                    />
                                    <span className="switch-state bg-primary"></span>
                                  </Label>
                                </Media>
                              </Media>
                            </div>
                          </Col>
                          <Col className="col-4">
                            <div style={{ display: 'inline-flex' }}>
                              <Media>
                                <Label
                                  className="col-form-label m-r-10"
                                  style={{ color: getStyle().color }}
                                >
                                  Erro operacional:
                                </Label>
                                <Media body className="text-end icon-state switch-sm">
                                  <Label className="switch">
                                    <Input
                                      type="checkbox"
                                      disabled={false}
                                      checked={formData.operationalError}
                                      onChange={() => {
                                        onChangeInput(
                                          'operationalError',
                                          !formData.operationalError,
                                        );
                                        onChangeInput('deviation', formData.operationalError);
                                      }}
                                    />
                                    <span className="switch-state bg-primary"></span>
                                  </Label>
                                </Media>
                              </Media>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  )}
                </div>
                <div className={getStyle().type === 0 ? 'dark' : ''}>
                  <textarea
                    rows={5}
                    placeholder="Observação"
                    className="form-control form-control"
                    value={formData.observation}
                    onChange={e => onChangeInput('observation', e.target.value)}
                  ></textarea>
                </div>
                <div className="header-top" style={{ paddingTop: '10px' }}>
                  <label />
                  <button className="btn btn-info" onClick={onSave}>
                    EVENTO AUDITADO
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};
