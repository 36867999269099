// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  filter = 'filter',
  coupon = 'coupon',
}

export interface ModalStyle {
  type: number;
  background: string;
  color: string;
}
